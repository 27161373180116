import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import ImageFB from "../components/fm_image"
import SEO from "../components/seo"
import SubscribeForm from "../components/SubscribeForm"
import { Typography } from "@material-ui/core"
// import { useTheme } from '@material-ui/core/styles';

const css = {
  display: "grid",
  maxWidth: `auto`,
  justifyItems: `stretch`,
  alignItems: `stretch`,
  overflow: `hidden`
};

const IndexPage = () => (
  <Layout>
    <SEO title="Home" description="Homepage" />
    <Typography variant="h5" color="primary">
      You are about to experience the best thing since puff puff...
    </Typography>
    <br />
    <Typography variant="h6" color="primary">
      Kidding it wouldn't be food, most definitely an app...
    </Typography>
    <br />
    <br />
    <SubscribeForm> </SubscribeForm>
    <div style={css} className="figures">
      <div className="figure-1">
        <ImageFB />
      </div>
      <div></div>
      <div className="figure-2">
        <Image />
      </div>
    </div>
  </Layout>
)

export default IndexPage
