import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
// import { useStaticQuery, graphql } from "gatsby"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import { Typography } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
// import { useTheme } from '@material-ui/core/styles';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import Alert from '@material-ui/lab/Alert';
// import theme from "./../theme.js"

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export default class SubscribeForm extends React.Component {


    constructor(props) {
        super(props);
        // const data = {}

        this.state = {
            email: '',
            message: '',
            formTitle: props?.formTitle || `Join the waitlist and you would be notified when this gets released?`,
            result: null,
            alertOpen: true
        };
    }

    componentDidMount() {
    }

    _handleChange = async (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    };

    _handleSubmit = async (e) => {
        e.preventDefault();
        const result = await addToMailchimp(this.state.email);
        this.setState({ message: result.msg.split("<a")[0] || "Already on the waitlist", result: result, alertOpen: true });
        // setInterval(this.timer, 5000);
    }

    closeAlert = () => {
        this.setState({ alertOpen: false });
    }

    splitString = async () => {
        return this.state.result.msg.split("<a")[0];
    }

    // const theme = useTheme();

    // const screenExtraLarge = useMediaQuery(theme.breakpoints.only('xl'));
    // const screenLarge = useMediaQuery(theme.breakpoints.only('lg'));
    // const screenMedium = useMediaQuery(theme.breakpoints.only('md'));
    // const screenSmall = useMediaQuery(theme.breakpoints.only('sm'));
    // const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
    // const screenNarrow = useMediaQuery('(max-width:340px)');


    render() {

        return (
            <div className="subscribe-form-grp" >
                <div className="subscribe-form-bg"></div>
                <form className="subscribe-form" onSubmit={this._handleSubmit}>
                    <Collapse in={this.state.alertOpen}>
                        {this.state.result?.result != null && this.state.result?.result == "success" &&
                            <div>
                                <Alert severity="success" icon={false} onClose={this.closeAlert}>You have successfully been added to the waitlist &#x1F601;</Alert>
                            </div>
                        }
                        {this.state.result?.result != null && this.state.result?.result == "error" &&
                            <div>
                                <Alert severity="error" icon={false} onClose={this.closeAlert}>Oops &#x1F625;! There was a slight hiccup, please try again. {this.state.message}</Alert>
                            </div>
                        }
                    </Collapse>

                    <Typography variant="body1" color="inherit">{this.state.formTitle}</Typography>
                    <br />
                    <TextField
                        id="outlined-email-input"
                        label="Email"
                        type="email"
                        name="email"
                        placeholder="example@email.com"
                        autoComplete="email"
                        variant="outlined"
                        color="primary"
                        onChange={this._handleChange}
                    />
                    <br />
                    <Button
                        variant="contained"
                        color="default"
                        label="Submit"
                        type="submit"
                    >
                        <Typography variant="button">Join Waitlist</Typography>
                    </Button>
                    <a target="_blank" href="https://sites.google.com/view/lny-web/home">
                        <Typography color="initial" variant="caption">Privacy Policy</Typography>
                    </a>
                </form>
            </div>
        );
    }
}
